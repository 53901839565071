import { ModelTypes } from "../zeus/index";
import { User_roles } from "~/zeus";

export const getAvatarUrl = ({ member }: { member: ModelTypes["User"] }) => {
  if (member?.organiser?.logo) {
    return member?.organiser?.logo?.url;
  }

  return member?.member?.avatar?.url;
};

export const userAvatar = (user: ModelTypes["User"]) => {
  if(user?.roles?.includes(User_roles.organiser)) {
    return user?.organiser?.logo?.url;
  }

  return user?.member?.avatar?.url;
};
